.title{
    text-decoration: none;
    font-size: large;
   
}
.row{
    padding-top:2rem
}
.title:hover{
    text-decoration: underline;
}
h1{
    font-size:1.4rem;
  
}
.price{
    padding-top: 0.5rem;
}