/* .app__feedback{
    display: flex;
    justify-content: space-between;
    height: 160vh;
} */
.app__feedback-head{
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
}
.app__wrapper1{
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    min-height: 40rem;
}

.v1__51 {
    width: 10rem;
    margin-left: 30rem;
    text-align: center;
    text-align: center;     
    font-family: 'Rufina';
    color: var(--color-golden);
    /* font-family: 'Sansita'; */
    font-style: normal;
    font-weight:600;
    font-size:90px;
    line-height: 140px;  
}
.img__rs2{
    transform: scaleY(-1);
}
.app__feedback-content{
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
}

.d2{
  align-items: flex-end;
}
.app__feedback-content-img{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;
    /* justify-content: flex-start; */
}
.app__feedback-wrap{
    display: flex;
    justify-content: space-around;
}
.img__fb{
    width:80%;
    border: 5px solid var(--color-black);
    box-shadow: 0 3px 10px rgb(0,0,0.2);
    padding: 1rem;
    margin-top: 3rem;
}
.text2{
    font-family: 'Rufina';
    max-width: 300px;
    font-style: oblique;
    font-size: 2.5rem;
    text-align: center;
    padding-top: 3rem;
    /* margin-left: 13rem; */
  }
  .text3{
    font-family: 'Rufina';
   max-width: 300px;
   font-style: oblique;
   font-size: 2.5rem;
   text-align: left;
   margin-left: 3rem;
   padding-left: 3rem;
  }
  .name3{
    text-align: center;
    font-size: 30px;
    margin-top: 5px;
    font-family: 'Rufina';
    font-style: normal;
    font-weight: 700;
  }
  .name2{  
    text-align: center;
  }

  @media screen and (max-width: 1450px) {
    .app__feedback{
        height: 100%;
        flex-direction: column;
        justify-content: space-around;
    }
    .img__rs2{
        display: none;
    }
    .img__rs{
        width: 10rem;
    }
    .v1__51{
        width: 100%;
        margin: 0;
        font-size: 6rem;
    }
    .app__feedback-wrap{
        margin-top: 2rem;
        display: flex;
        margin-bottom: 2rem;
    }
    .text2{
        margin-top: 2rem;
        font-size: 2.3rem;
    }
    .text3{
        margin-top: 2rem;
        font-size: 2.3rem;
    }
}
@media screen and (max-width: 700px) {
    .img__rs{
        width: 6rem;
    }
    .v1__51{
        width: 100%;
        margin: 0;
        font-size: 6rem;
    }
    .app__feedback-wrap{
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
    }
    .app__feedback-content-img{
        margin: auto;
    }
    .text2{
        margin:auto;
        font-size: 2.3rem;
    }
    .text3{
        text-align: center;
        margin:auto;
        font-size: 2.3rem;
        padding-left: 0;
    }
}
