.section__padding {
    padding: 4rem 6rem;
  }
.section__padding1{
    padding-top: 4rem;
    padding-bottom: 4rem;
}
.flex__center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.app__bg {
    background: url('./assets/vlgt.png');
    background-position: center;
    background-size: cover;
    background-repeat: repeat;
    background-attachment: fixed;
}
.app__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 100vh;
}
.p__opensans {
    font-family: 'Times New Roman';
    color: white;
    font-weight: 400;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    line-height: 28px;
    font-size: 1.8rem;
    /* text-decoration: none; */
}
.custom__button {
    background-color: var(--color-crimson);
      color: var(--color-black);
      font-family: var(--font-base);
      font-weight: 700;
      letter-spacing: 0.04em;
      line-height: 28px;
      font-size: 17px;
      padding: 0.3rem 1rem;
      border-radius: 1px;
      border: none;
      outline: none;
      cursor: pointer;
  }
  @media screen and (min-width: 2000px) {
    .custom__button,
    .p__cormorant {
      font-size: 37px;
      line-height: 67px;
    }
  
    .p__opensans {
      font-size: 30px;
      line-height: 50px;
    }
  
    .headtext__cormorant {
      font-size: 150px;
      line-height: 210px;
    }
  
    .spoon__img {
      width: 80px;
    }
  }
  
  @media screen and (max-width: 1150px) {
    .app__wrapper {
      flex-direction: column;
    }
  
    .app__wrapper_img {
      margin: 5rem 0 0 0;
    }
  
    .app__wrapper_img-reverse {
      margin: 0 0 5rem 0;
    }
  
    .app__wrapper_img img {
      width: 100%;
    }
  }
  
  @media screen and (max-width: 850px) {
    .section__padding {
      padding: 4rem;
    }
    
  }
  
  @media screen and (max-width: 650px) {
    .section__padding {
      padding: 4rem 2rem;
    }
    
    .p__cormorant {
      font-size: 21px;
    }
  
    .p__opensans {
      font-size: 14px;
    }
  }
  
  @media screen and (max-width: 450px) {
    .p__opensans {
      font-size: 12px;
    }
  
    .p__cormorant {
      font-size: 19px;
    }
  
    .headtext__cormorant {
      font-size: 45px;
      line-height: 70px;
    }
  }
  