.header {
    position: fixed;
    height: 120px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    transition: .7s ease-in;
    overflow: hidden;
}
.dropdown-menu{
    font-size: 2rem;
    text-transform: none;
}

#username{
    padding: 20px;
    border-radius: 25px;
    background-color: var(--color-crimson);
    color: var(--color-black);
    font-family: var(--font-alt);
    font-weight: 700;
    letter-spacing: 0.04em;
    line-height: 28px;
    font-size: 12px;
    margin-bottom: 1rem;
    padding: 0.3rem 1.2rem;
    border-radius: 25px;
    border: none;
    outline: none;
    cursor: pointer;
}
.header_bg{
    width: 100%;
    background-image: linear-gradient(to right, var(--color-golden),#FC5C7D); 
    background-position: center;
    background-size: cover;
    background-repeat: repeat;
    background-attachment: fixed;
    box-shadow: 0 3px 10px rgb(0.4,0.1,0.2,0.3);
}
.header_bg1{
    position: fixed;
    height: 125px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    transition: .7s ease-in;
    overflow: hidden;
    background-image: linear-gradient(to right, var(--color-golden),#FC5C7D	); 
    box-shadow: 0 3px 10px rgb(0,0,0,0.3);
}
.app__navbar {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    /* background-image: url('../../assets/vlgt.png'); */
}

.app__navbar-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: fit-content;
}
.app__navbar-logo_content{
    position: relative;
    width: 300px;
    right: 16px;
    font-family: 'Rufina';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    color: white;
}
.no-deco{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: fit-content;
    text-decoration: none;
}
.app__navbar-logo img {
    position: absolute;
    height: 80% ;
    width: 50%;
}

.app__navbar-links {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
}

.app__navbar-links li {
    margin: 0 1.7rem;
    cursor: pointer;
    text-decoration: none;
}

.app__navbar-links .setColor:hover {
    color: var(--color-grey);
}

.app__navbar-login {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 1rem;
}

.app__navbar-login a {
    margin: 0 1rem;
    text-decoration: none;
    transition: .5s ease;
}
.setColor {
    color: white;
    margin: 0 1rem;
    transition: .5s ease;
}

.app__navbar-login a:hover {
    border-bottom: 1px solid var(--color-golden);
}
.app__navbar-links div {
    width: 2px;
    height: 20px;
    background: var(--color-golden);
}
.app__navbar-login div {
    width: 2px;
    height: 20px;
    background: var(--color-golden);
    margin-right: 1.5rem;
}

.app__navbar-smallscreen {
    display: none;
}

.app__navbar-smallscreen_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-image: linear-gradient(to right, var(--color-golden),#FC5C7D)  ;
        transition: .5s ease;
    flex-direction: column;
    z-index: 5;
}

.app__navbar-smallscreen_overlay .overlay__close {
    font-size: 22px;
    color: var(--color-golden);
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
}

.app__navbar-smallscreen_links {
    list-style: none;
}

.app__navbar-smallscreen_links li {
    margin: 2rem;
    cursor: pointer;
    color: var(--color-golden);
    font-size: 2rem;
    text-align: center;
    font-family: var(--font-base);
}

.app__navbar-smallscreen_links li:hover {
    color: var(--color-white);
}

@media screen and (max-width: 2000px) {
    .app__navbar-logo img {
        width: 210px;
    }
}

@media screen and (max-width: 1150px) {
    .app__navbar-links {
        display: none;
    }

    .app__navbar-smallscreen {
        display: flex;
    }
}

@media screen and (max-width: 650px) {
    .app__navbar {
        padding: 1rem;
    }

    .app__navbar-login {
        display: none;
    }

    .app__navbar-logo h1{
        font-size: 2rem;
    }
    .app__navbar-logo img {
        width: 80px;
    }
}
.no-deco{
    text-decoration: none;
}