
.app__header{
    height: 120vh;
}
.app__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin-top: 16rem;
    /* min-height: 100vh */
    /* margin-right: 9rem;    */
}
.app__wrapper4{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 110vh;
}
.app__content-h1{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    font-family: 'Sansita';
    font-style: normal;
    font-weight: 400;
    font-size: 120px;
    line-height: 152px;
    color: var(--color-golden);
}
.app__content .spoon__img{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.app__content p{
    width: 100%;
    display: flex;
    padding-left: 34rem;
    justify-content: center;
    align-items: flex-start;
    font-family: 'Times New Roman';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 48px;
}
@media screen and (min-width: 2000px) {
    .app__content-h1 {
        font-size: 180px;
        line-height: 210px;
    }
}

@media screen and (max-width: 1150px) {
    .app__content-h1 {
        font-size: 110px;
        padding: 0;
    }
}

@media screen and (max-width: 650px) {
    .app__content-h1 {
        font-size: 90px;
        line-height: 87px;
        padding: 0;
        align-items: flex-start;
        justify-content: center;
    }
    .app__content p{
        padding-left: 0;
        font-size: 25px;
    }
}

@media screen and (max-width: 450px) {
    .app__content-h1 {
        font-size: 50px;
        line-height: 70px;
        padding: 0;
    }
    .app__content p{
        font-size: 25px;
    }
}