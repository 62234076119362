.app__gallery {
    flex-direction: row;
    width: 100%;
    height: 70vh;
    /* background: url('../../assets/bg.png'); */
    /* background-image: linear-gradient(to right,#6A82FB,#FC5C7D	);  */
    background-color: #fff;
    padding: 4rem 0 4rem 6rem;
}
.headtext__cormorant{
    font-family: var(--font-base);
    color: var(--color-golden);
    font-size: 64px;
    line-height: 83.2px;
    letter-spacing: 0.04em;
    text-transform: capitalize;
}
.app__gallery-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    min-width: 500px;
    padding-right: 2rem;
}
.app__list{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    flex-direction: column;
}

.app__list .animate__1 {
    position: relative;
    animation-name: animateText;
    animation-duration: 4s;
    animation-duration: 0.01s;
    animation-iteration-count: 1;  
  	animation-fill-mode: forwards;	
  	animation-play-state: paused;
}

.app__list .animate__2 {
    position: relative;
    animation-name: animateText__2;
    animation-duration: 4s;
    animation-duration: 0.01s;
    animation-iteration-count: 1;  
  	animation-fill-mode: forwards;	
  	animation-play-state: paused;
}

.app__list .animate__3 {
    position: relative;
    animation-name: animateText__3;
    animation-duration: 4s;
    animation-duration: 0.01s;
    animation-iteration-count: 1;  
  	animation-fill-mode: forwards;	
  	animation-play-state: paused;
}

.app__list .animate__4 {
    position: relative;
    animation-name: animateText__4;
    animation-duration: 4s;
    animation-duration: 0.01s;
    animation-iteration-count: 1;  
  	animation-fill-mode: forwards;	
  	animation-play-state: paused;
}

.app__list .animate__5 {
    position: relative;
    animation-name: animateText__5;
    animation-duration: 4s;
    animation-duration: 0.01s;
    animation-iteration-count: 1;  
  	animation-fill-mode: forwards;	
  	animation-play-state: paused;
}

.app__list .animate__6 {
    position: relative;
    animation-name: animateText__6;
    animation-duration: 4s;
    animation-duration: 0.01s;
    animation-iteration-count: 1;  
  	animation-fill-mode: forwards;	
  	animation-play-state: paused;
}

@keyframes animateText {
    from {left:0px; top:0px;}
    to {left:50px; top:0px;}
}

@keyframes animateText__2 {
    from {left:5px; top:0px;}
    to {left:80px; top:0px}
}

@keyframes animateText__3 {
    from {left:10px; top:0px;}
    to {left:110px; top:0px;}
}

@keyframes animateText__4 {
    from {left:10px; top:0px;}
    to {left:150px; top:0px;}
}

@keyframes animateText__5 {
    from {left:20px; top:0px;}
    to {left:180px; top:0px;}
}

@keyframes animateText__6 {
    from {left:25px; top:0px;}
    to {left:210px; top:0px;}
}

.app__list li p:hover {
    animation-play-state: running;
}

.app__gallery-content button {
    margin-top: 1rem;
}

.app__gallery-images {
    flex: 1;
    display: flex;
    flex-direction: row;
    max-width: 50%;
    position: relative;
}

.app__gallery-images_container {
    display: flex;
    flex-direction: row;
    width: max-content;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;  
}

.app__gallery-images_container::-webkit-scrollbar {
    display: none;
}

.app__gallery-images_card {
    position: relative;
    min-width: 301px;
    height: 447px;
    margin-right: 2rem;
}

.gallery__image-icon {
    position: absolute;
    color: #fff;
    font-size: 2rem;
    opacity: 0;
    transition: .5s ease;
    cursor: pointer;
}

.app__gallery-images_card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    transition: .5s ease;
}

.app__gallery-images_card:hover img {
    opacity: .35;
}

.app__gallery-images_card:hover .gallery__image-icon {
    opacity: 1;
}

.app__gallery-images_arrows {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 1rem;
    position: absolute;
    bottom: 5%;
}

.gallery__arrow-icon {
    color: var(--color-golden);
    font-size: 5rem;
    cursor: pointer;
    background: var(--color-black);
    border-radius: 5px;
}

.gallery__arrow-icon:hover {
    color: var(--color-white);
}

@media screen and (min-width: 2000px) {
    .app__gallery-content button {
        margin-top: 2rem;
    }

    .app__gallery-content {
        min-width: 1000px;
        padding-right: 4rem;
    }

    .app__gallery-images_card {
        min-width: 400px;
        height: 547px;
    }
}

@media screen and (max-width: 1150px) {
    .app__gallery {
        flex-direction: column;
    }

    .app__gallery-images {
        max-width: 100%;
        margin: 5rem 0;
    }
    .app__gallery{
        height: 120vh;
    }

}

@media screen and (max-width: 850px) {
    .app__gallery {
        padding: 4rem 0 4rem 4rem;
    }
    .app__gallery{
        height: 120vh;
    }
    .app__list{
       margin-bottom: auto;
    }
}

@media screen and (max-width: 650px) {
    .app__gallery {
        padding: 4rem 0 4rem 2rem;
    }  
    
    .app__gallery-content {
        min-width: 100%;
    }

    .app__gallery-images_card {
        min-width: 240px;
        height: 320px;
    }
}
